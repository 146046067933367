import React, { Component } from 'react';
import "./Register.css"
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Logo from './logo-robot.png'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Grid } from  'react-loader-spinner'

const liff = window.liff;

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoading:false,
      registered : false,
      role:'user',
      userLineName: '',
      userLineID: '',
      fname: '',
      mname:'',
      lname: '',
      studentID:'',
      email:'',
      faculty:'',
      major:'',
      university:'Bangkok University',
      phone:'',
      birthday:new Date(),
      lockbtn:false,
      qrtemp:null

    };
  }

  componentDidMount = async() => {
    this.setState({showLoading:true})
    await liff.init({ liffId: `2000696104-J4Pay1OQ` }).catch(err=>{throw err});
    let useridcheck = 0;
    if (liff.isLoggedIn()) {
      let getProfile = await liff.getProfile();
      useridcheck = getProfile.userId;
      this.setState({
        userLineName: getProfile.displayName,
        userLineID: getProfile.userId,
      });
    }else{
      liff.login();
    }
    try {
      const response = await axios.post('https://burs.pythonanywhere.com/checkregister', {
        key:useridcheck
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      this.setState({
        registered: response.data == 'yes/user' ? true : false,
        role: response.data == 'yes/q4kvh21pyMEsZ311v9vY' ? 'q4kvh21pyMEsZ311v9vY' : 'user',
      });
    } catch (error) {
      console.error('Error:', error);
    }
    this.setState({showLoading:false})
  }
  selectmajor = (faculty) =>{
    if(faculty == 'School of Entrepreneurship and Management'){
      return ['Select your major','Entrepreneurship']
    }
    else if(faculty == 'School of Digital Media and Cinematic Arts'){
      return ['Select your major','Film','Digital Media']
    }
    else if(faculty == 'School of Law'){
      return ['Select your major','Law Program']
    }
    else if(faculty == 'School of Communication Arts'){
      return ['Select your major','Event Production and MICE Management','Event Production and MICE Management','Digital Advertising','Digital Public Relations','Influencer Branding','Digital communication and media','Creative Branding','Broadcasting and Streaming Media Production','Performing Arts','Digital Journalism']
    }
    else if(faculty == 'School of Business Administration'){
      return ['Select your major','Digital Marketing (Digital Entrepreneur & Brand Influencer)','Digital Marketing (Digital Entrepreneur & Brand Influencer)','International Business Management','Marketing','Finance','Management','International Business Management','Logistics and Supply Chain Management','Digital Marketing','Financial and Investment Planning']
    }
    else if(faculty == 'School of Accounting'){
      return ['Select your major','Accounting Program']
    }
    else if(faculty == 'School of Humanities and Tourism Management'){
      return ['Select your major','Travel and Cruise Management','Hotel Management','Hotel Management','English']
    }
    else if(faculty == 'School of Engineering'){
      return ['Select your major','Computer and Robotics Engineering','Artificial Intelligence Engineering and Data Science','Multimedia and Entertainment Engineering','Electrical Engineering']
    }
    else if(faculty == 'School of Fine and Applied Arts'){
      return ['Select your major','Communication Design','Fashion Design','Art and Design']
    }
    else if(faculty == 'School of Architecture'){
      return ['Select your major','Architecture','Interior Architecture']
    }
    else if(faculty == 'School of Information Technology and Innovation'){
      return ['Select your major','Computer Science - Data Science and Cybersecurity','Computer Science','Games and Interactive Media','Information Technology']
    }
    else if(faculty == 'School of Economics and Investment'){
      return ['Select your major','Economics']
    }
    else if(faculty == 'Bangkok University International'){
      return ['Select your major','Business English','Communication Strategy and Ideation','Creative Communication Design','Culinary Arts and Design','Entrepreneurship','Innovative Media Production','International Tourism and Hospitality Management','Marketing']
    }
    else if(faculty == 'Bangkok University Chinese International'){
      return ['Select your major','Business Chinese']
    }
    else{
      return ['Select your faculty']
    }
  }
  handleChangeFaculty = (event) => {
    this.setState({faculty:event.target.value});
  }
  handleChangeFname = (event) => {
    var english = /^[A-Za-z0-9]*$/;
    if(english.test(event.target.value)){
      this.setState({fname:event.target.value});
    }
    else{
      const MySwal = withReactContent(Swal)
      let timerInterval
      MySwal.fire({
        icon: 'warning',
        title: 'กรุณากรอกเป็นภาษาอังกฤษ',
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
    }
  }
  handleChangeMname = (event) => {
    var english = /^[A-Za-z0-9]*$/;
    if(english.test(event.target.value)){
      this.setState({mname:event.target.value});
    }
    else{
      const MySwal = withReactContent(Swal)
      let timerInterval
      MySwal.fire({
        icon: 'warning',
        title: 'กรุณากรอกเป็นภาษาอังกฤษ',
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
    }
  }
  handleChangeLname = (event) => {
    var english = /^[A-Za-z0-9]*$/;
    if(english.test(event.target.value)){
      this.setState({lname:event.target.value});
    }
    else{
      const MySwal = withReactContent(Swal)
      let timerInterval
      MySwal.fire({
        icon: 'warning',
        title: 'กรุณากรอกเป็นภาษาอังกฤษ',
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
    }
    
  }
  handleChangeEmail = (event) => {
    this.setState({email:event.target.value});
  }
  handleChangeMajor = (event) => {
    this.setState({major:event.target.value});
  }
  handleChangePhone = (event) => {
    this.setState({phone:event.target.value});
  }
  handleChangeBirth = (value) => {
    this.setState({birthday:value});
  }
  handleChangeSid = (event) => {
    this.setState({studentID:event.target.value});
  }
  complete = async(e) => {
    if(this.state.lockbtn == false){
      e.preventDefault();
      const MySwal = withReactContent(Swal)
      if(!(/\S+@\S+\.\S+/.test(this.state.email))||(this.state.faculty in ['Select your faculty',''])||(this.state.major in ['Select your major',''])||(this.state.fname == '')||(this.state.lname == '')||(this.state.studentID == '')||(this.state.phone == '')||(this.state.birthday == new Date())){
        MySwal.fire({
          icon: 'warning',
          title: 'Oops...Something went wrong!',
          text: 'Error in '+(this.state.fname == ''?'First-Name ':'')+(this.state.lname == ''?'Last-Name ':'')+(/\S+@\S+\.\S+/.test(this.state.email)?'':'Email ')+(this.state.faculty in ['Select your faculty','']?'':'Faculty ')+(this.state.major in ['Select your major','']?'':'Major ')+(this.state.phone == ''?'Phone ':'')+(this.state.birthday == new Date()?'':'Birth-Day '),
        })
      }
      else{
          this.setState({lockbtn:true})
          let timerInterval
          MySwal.fire({
            icon: 'success',
            title: 'Success',
            html: 'This window will close in <b></b> milliseconds.',
            timer: 2500,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          })
          try {
            const response = await axios.post('https://burs.pythonanywhere.com/register', {
              "fname":this.state.fname,
              "mname":this.state.mname,
              "lname":this.state.lname,
              "userLineID":this.state.userLineID,
              "studentID":this.state.studentID,
              "email":this.state.email,
              "faculty":this.state.faculty,
              "major":this.state.major,
              "university":this.state.university,
              "phone":this.state.phone,
              "birthday":this.state.birthday
            }, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
      
          } catch (error) {
            console.error('Error:', error);
          }
          liff.closeWindow();
        }

      }
  }
  async scanCodeV2(){
    try {
      const result = await liff.scanCodeV2();
      this.setState({showLoading:true})
      const response = await axios.post('https://burs.pythonanywhere.com/checkin', {
        key:result.value,
        from:this.state.userLineID
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.setState({showLoading:false})
      if(response.data == 'success'){
        const MySwal = withReactContent(Swal)
        let timerInterval
        MySwal.fire({
        icon: 'success',
        title: 'Success',
        html: 'This window will close in <b></b> milliseconds.',
        timer: 2500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
      }
      else if(response.data == 'done'){
        const MySwal = withReactContent(Swal)
        let timerInterval
        MySwal.fire({
        icon: 'warning',
        title: 'Already checked in',
        html: 'This window will close in <b></b> milliseconds.',
        timer: 2500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
      }
    } catch(error) {
      console.log('scanCodeV2', error);
    }
  }
  render() {
    return (
      this.state.showLoading?
      <div className="App">
        <div className="center">
        <Grid
          height="80"
          width="80"
          color="#ffffff"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        </div>
      </div>
      :
      this.state.registered?
      <div className="App">
        <div className="center">คุณลงทะเบียนไปแล้ว มีปัญหาโปรดติดต่อห้องประชาสัมพันธ์</div>
      </div>
      : this.state.role == 'q4kvh21pyMEsZ311v9vY'?
      <div className="App">
        <div className="center">
        <button onClick={()=>{this.scanCodeV2()}}>QR Code Reader</button>
        </div>
      </div>
      :
      <div className="App">
          <div className="center">
            <img src={Logo} className='logo'/>
            <Form className='size-control'>
              <Form.Label>LINE : {this.state.userLineName}</Form.Label>
              <div className='text-left'>
                <Form.Group className="mb-3 inputform" controlId="exampleForm.ControlInput1">
                <Form.Label>First name (English Only)</Form.Label>
                <Form.Control type="text" value={this.state.fname} onChange={this.handleChangeFname} required/>
              </Form.Group>
              <Form.Group className="mb-3 inputform" controlId="exampleForm.ControlInput1">
                <Form.Label>Middle name *optional (English Only)</Form.Label>
                <Form.Control type="text" value={this.state.mname} onChange={this.handleChangeMname} required/>
              </Form.Group>
              <Form.Group className="mb-3 inputform" controlId="exampleForm.ControlInput1">
                <Form.Label>Last name (English Only)</Form.Label>
                <Form.Control type="text" value={this.state.lname} onChange={this.handleChangeLname} required/>
              </Form.Group>
              <Form.Group className="mb-3 inputform" controlId="exampleForm.ControlInput1">
                <Form.Label>Student ID</Form.Label>
                <Form.Control type="text" onChange={this.handleChangeSid} required/>
              </Form.Group>
              <Form.Group className="mb-3 inputform" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" onChange={this.handleChangeEmail} required/>
              </Form.Group>
              <Form.Label>Faculty</Form.Label>
              <Form.Select aria-label="Default select example" className="inputform" onChange={this.handleChangeFaculty} required>
                <option>Select your faculty</option>
                <option>School of Entrepreneurship and Management</option>
                <option>School of Digital Media and Cinematic Arts</option>
                <option>School of Law</option>
                <option>School of Communication Arts</option>
                <option>School of Business Administration</option>
                <option>School of Accounting</option>
                <option>School of Humanities and Tourism Management</option>
                <option>School of Engineering</option>
                <option>School of Fine and Applied Arts</option>
                <option>School of Architecture</option>
                <option>School of Information Technology and Innovation</option>
                <option>School of Economics and Investment</option>
                <option>Bangkok University International</option>
                <option>Bangkok University Chinese International</option>
              </Form.Select>
              <Form.Label>Major</Form.Label>
              <Form.Select aria-label="Default select example" className="inputform" onChange={this.handleChangeMajor} required>
                {this.selectmajor(this.state.faculty).map((major) => (<option>{major}</option>))}
              </Form.Select>
              <Form.Label>University</Form.Label>
              <Form.Control type="text" placeholder="Bangkok University" className="inputform" readOnly />
              <Form.Group className="mb-3 inputform"  controlId="exampleForm.ControlInput1">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="number" onChange={this.handleChangePhone} required/>
              </Form.Group>
              <Form.Label>Birth Day</Form.Label>
              <p>
              <DatePicker selected={this.state.birthday} dateFormat="dd/MM/yyyy" className="inputform set-size" onChange={this.handleChangeBirth}/>
              </p>
              </div>
              
              <Button variant="light" className='btn-submit' onClick={this.complete}>
                Register
              </Button>
            </Form>
          </div>
      </div>
    );
  }
}

export default App;