import React, { Component } from 'react';
import "./Register.css"
import { Grid } from  'react-loader-spinner'
import axios from 'axios';
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading:false,
      data:{
        "ME101":[],
        "register":[]
      }
    };
  }
  componentDidMount = async() => {
    this.setState({showLoading:true})
    try {
      const response = await axios.get('https://burs.pythonanywhere.com/kyqwwZIiCCYpsXPEAmLyzFe82yPIg3');
      this.setState({
        data: response.data
      });
    } catch (error) {
      console.error('Error:', error);
    }
    this.setState({showLoading:false})
  }
  render() {
    return (
      this.state.showLoading?
      <div className="App">
        <div className="center">
        <Grid
          height="80"
          width="80"
          color="#ffffff"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        </div>
      </div>
      :
      <div className="App">
        <div className="center">
          <div>
          <p>---------------ME101---------------</p>
          <p>Amount : {this.state.data['ME101'].length}</p>
          {this.state.data['ME101'].map((dat) => (<p>{dat.join(' ')}</p>))}
          </div>
          <div>
          <p>--------------Registered------------</p>
          <p>Amount : {this.state.data['register'].length}</p>
          {this.state.data['register'].map((dat) => (<p>{dat.join(' ')}</p>))}
          </div>
        </div>
      </div>
    );
  }
}

export default App;